import './howitworks.scss';

import React from 'react';
import SEO from '../components/Seo';
import feature1Img from '../../data/images/img1.jpg';
import feature2Img from '../../data/images/finCoachIcon.png';

const howitworksPage = () => {
  return (
    <div className="wrapper">
      <SEO title="How it works" />
      <h1>How It All Works</h1>

      <div className="features">

        <div className="feature">
          <div className="img">
            <img id="img1" src={feature1Img} height="100" alt="feature_img" />
          </div>
          <h3>Matching Technology</h3>
          <h4 className="subFeature">Where The Magic Happens</h4>
          <p>
            This technology uses information provided by millennials about their
            specific needs and budget on our platform and matches them with the
            right financial coach based on their needs.
          </p>
        </div>

        <div className="feature">
          <div className="img">
            <img id="img2" src={feature2Img} height="100" alt="feature_img" />
          </div>
          <h3>Financial Guidance</h3>
          <h4 className="subFeature">Create Positive Habits!</h4>
          <p>
            Walletgyde is transforming the traditional financial coaching industry
            with the help of modern technology. It connects millennials that are looking
            for financial guidance to the right financial coach to help them to be
            financially successful and customize their life.
            {' '}
          </p>
        </div>

      </div>

    </div>
  );
};

export default howitworksPage;
